<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" ref="navbarToggle" data-toggle="collapse" data-target="#navbarCollapse" aria-expanded="false" aria-controls="navbarCollapse">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item" :class="{ active: $route.name === 'home' }">
            <router-link class="nav-link" :to="{ name: 'home' }">
              Home
            </router-link>
          </li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item" :class="{ active: $route.name === 'profile' }" v-if="loggedIn">
            <router-link class="nav-link" :to="{ name: 'profile', params: { id: '@me' } }">
              Profile
            </router-link>
          </li>
          <li class="nav-item" v-if="loggedIn">
            <router-link class="nav-link" :to="{ name: 'logout' }">
              Logout
            </router-link>
          </li>
          <li class="nav-item" :class="{ active: $route.name === 'login' || $route.name === 'register' }" v-else>
            <router-link class="nav-link" :to="{ name: 'login' }">
              Login
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import Collapse from "bootstrap.native/dist/components/collapse-native.esm";
import { createNamespacedHelpers } from "vuex";
const { mapState: mapAccountState } = createNamespacedHelpers("account");
export default {
	data() {
		return {
			collapse: null,
			dropdown: null
		};
	},
	async mounted() {
		await this.$nextTick();
		this.collapse = new Collapse(this.$refs.navbarToggle);
	},
	computed: {
		...mapAccountState({
			loggedIn: state => state.token !== null && state.synced
		})
	}
};
</script>


<style lang="scss" scoped>
@import "../variables";
nav {
  border-bottom: 2px solid rgb(147, 193, 236);
  z-index: 10;
}

// nav #navbarCollapse .nav-item {
//   & a {
//     color: $color-text;
//     &:hover {
//       color: lighten($color-text, 20);
//     }
//   }
//   &.active a {
//     color: lighten($color-text, 30);
//   }
// }
// #account-info {
//   .btn {
//     background: $color-main;
//     color: $color-text;
//   }
//   .dropdown-menu {
//     background: $color-secondary;
//     .dropdown-divider {
//       border-color: darken($color-text, 15);
//     }
//     & .dropdown-item {
//       color: $color-text;
//       transition: 0.1s ease-in-out;
//       &:hover {
//         background: darken($color-secondary, 5);
//       }
//     }
//   }
// }
</style>
